(function () {
	'use strict';

	angular
		.module('app')
		.factory('salesforceShared', [
			'seedcodeCalendar',
			'calendarIO',
			'fullCalendarBridge',
			'daybackIO',
			'manageSettings',
			'utilities',
			'$sce',
			'manageFetch',
			salesforceShared,
		]);

	function salesforceShared(
		seedcodeCalendar,
		calendarIO,
		fullCalendarBridge,
		daybackIO,
		manageSettings,
		utilities,
		$sce,
		manageFetch
	) {
		return {
			getShared: getShared,
		};

		function getShared(apiType, api, createConnection) {
			var eventEditQueue = {};

			return {
				getEventEditQueue: getEventEditQueue,
				customFieldMutateInput: customFieldMutateInput,
				getSchedules: getSchedules,
				disableSchedule: disableSchedule,
				getUnscheduled: getUnscheduled,
				getMapEvents: getMapEvents,
				getEvents: getEvents,
				editEvent: editEvent,
				deleteEvent: deleteEvent,
				getContacts: getContacts,
				getProjects: getProjects,
				getResources: getResources,
				getFieldData: getFieldData,
				getPickList: getPickList,
				getRepeatingRule: getRepeatingRule,
				deleteSourceEvent: deleteSourceEvent,
				repeatingConfig: repeatingConfig,
				calculateEndDateTime: calculateEndDateTime,
				clearRecurringFields: clearRecurringFields,
				deleteRepeatingInstance: deleteRepeatingInstance,
				updateRepeatingEventUntil: updateRepeatingEventUntil,
			};

			function getEventEditQueue() {
				return eventEditQueue;
			}

			function updateRepeatingEventUntil(event, newUntil, callback) {
				var objectName = event.schedule.objectName;
				var recordId = event.recurringEventID;
				var scheduleId = event.schedule.id;
				var request = {
					RecurrenceEndDateOnly: newUntil.format('YYYY-MM-DD'),
				};
				api.updateRecord(
					objectName,
					recordId,
					callback,
					request,
					scheduleId
				);
			}

			function deleteRepeatingInstance(event, callback) {
				deleteEvent(event, callback, event.schedule);
			}

			function repeatingConfig() {
				return {
					repeatEnabled: true,
					schedulesEnabled: {
						Event: true,
						Task: true,
					},
					createOnly: true,
					footers: {until: true},
					intervalShows: {
						Daily: true,
						Weekly: true,
						Monthly: true,
					},
					yearlyMultiSelect: false,
					dateChangeClear: true,
				};
			}

			function clearRecurringFields(editObject) {
				if (editObject.IsRecurrence) {
					delete editObject.IsRecurrence;
				}
				if (editObject.RecurrenceStartDateTime) {
					delete editObject.RecurrenceStartDateTime;
				}
				if (editObject.RecurrenceStartDateOnly) {
					delete editObject.RecurrenceStartDateOnly;
				}
				if (editObject.RecurrenceEndDateOnly) {
					delete editObject.RecurrenceEndDateOnly;
				}
				if (editObject.RecurrenceTimeZoneSidKey) {
					delete editObject.RecurrenceTimeZoneSidKey;
				}
				if (editObject.RecurrenceType) {
					delete editObject.RecurrenceType;
				}
				if (editObject.RecurrenceInterval) {
					delete editObject.RecurrenceInterval;
				}
				if (editObject.RecurrenceDayOfWeekMask) {
					delete editObject.RecurrenceDayOfWeekMask;
				}
				if (editObject.RecurrenceDayOfMonth) {
					delete editObject.RecurrenceDayOfMonth;
				}
				if (editObject.RecurrenceInstance) {
					delete editObject.RecurrenceInstance;
				}
				if (editObject.RecurrenceMonthOfYear) {
					delete editObject.RecurrenceMonthOfYear;
				}
			}

			function calculateEndDateTime(request, ruleObject) {
				var dayAdd;
				var next;
				var limit;
				var thisMoment = moment(request.start.dateTime);
				var year;
				var month;
				var date;
				//want this to evaluate every time.
				// if(ruleObject.until){
				// 	return moment(ruleObject.until, 'L').format('YYYY-MM-DD');
				// }
				if (ruleObject.frequency === 'Daily') {
					dayAdd = 100 * ruleObject.interval - ruleObject.interval;
				} else if (ruleObject.frequency === 'Weekly') {
					var i = 0;
					limit = 52;
					next = moment(request.start.dateTime);
					while (i < limit) {
						next = nextMoment(
							next,
							ruleObject.days,
							ruleObject.interval
						);
						if (!next) {
							return false;
						}
						i++;
					}
					dayAdd = next.diff(moment(request.start.dateTime), 'days');
				} else if (ruleObject.frequency === 'Monthly') {
					limit = ruleObject.interval * 60 - ruleObject.interval;
					var years = Math.floor(limit / 12);
					year = thisMoment.year();
					var months = limit - years * 12;
					year = years + year;
					next = moment(thisMoment)
						.add(years, 'years')
						.add(months, 'months');
					if (ruleObject.days) {
						//defining by nth day, backtrack to the right day.
						var thisDay = ruleObject.days[0].split(' ')[1];
						var dayMap = {
							Sunday: 0,
							Monday: 1,
							Tuesday: 2,
							Wednesday: 3,
							Thursday: 4,
							Friday: 5,
							Saturday: 6,
						};
						thisDay = dayMap[thisDay];
						while (next.day() > thisDay) {
							next.subtract(1, 'days');
						}
					}
					dayAdd = next.diff(moment(request.start.dateTime), 'days');
				} else if (ruleObject.frequency === 'Yearly') {
					limit = 10;
					year = thisMoment.year() + (limit - 1);
					month = thisMoment.month();
					date = thisMoment.date();
					next = moment([year, month, date]);
					dayAdd = next.diff(moment(request.start.dateTime), 'days');
				}
				return moment(request.start.dateTime)
					.add(dayAdd, 'days')
					.format('YYYY-MM-DD');
			}

			function getRepeatingRule(request, ruleObject) {
				var dayAdd;
				var result = {};
				var next;
				var limit;
				var thisMoment = moment(request.start.dateTime);
				var year;
				var month;
				var date;
				result.IsRecurrence = true;
				if (request.allowAllDay) {
					if (request.allDay) {
						result.RecurrenceStartDateTime = moment(
							request.start.dateTime
						).format('YYYY-MM-DD');
					} else {
						result.RecurrenceStartDateTime = moment(
							request.start.dateTime
						).format();
					}
				} else {
					result.RecurrenceStartDateOnly = moment(
						request.start.dateTime
					).format('YYYY-MM-DD');
				}

				result.RecurrenceInterval = ruleObject.interval;
				if (ruleObject.frequency === 'Daily') {
					result.RecurrenceType = 'RecursDaily';
					dayAdd =
						100 * result.RecurrenceInterval -
						result.RecurrenceInterval;
				} else if (ruleObject.frequency === 'Weekly') {
					result.RecurrenceType = 'RecursWeekly';
					result.RecurrenceDayOfWeekMask = dayArrayToIndex(
						ruleObject.days
					);
					var i = 0;
					limit = 52;
					next = moment(request.start.dateTime);
					while (i < limit) {
						next = nextMoment(
							next,
							ruleObject.days,
							result.RecurrenceInterval
						);
						i++;
					}
					dayAdd = next.diff(moment(request.start.dateTime), 'days');
				} else if (ruleObject.frequency === 'Monthly') {
					if (ruleObject.dayNumbers) {
						result.RecurrenceType = 'RecursMonthly';
						result.RecurrenceDayOfMonth = ruleObject.dayNumbers[0];
					} else {
						result.RecurrenceType = 'RecursMonthlyNth';
						result.RecurrenceInstance =
							ruleObject.days[0].split(' ')[0];
						result.RecurrenceDayOfWeekMask = dayArrayToIndex([
							ruleObject.days[0].split(' ')[1],
						]);
					}
					limit = 60 * result.RecurrenceInterval - 1;
					var years = Math.floor(limit / 12);
					year = thisMoment.year() + years;
					var months = limit - years * 12; //11
					month = thisMoment.month();
					month = month + months;
					year = months > 11 ? year + 1 : year;
					month = month > 11 ? month - 11 : month;
					date = thisMoment.date();
					next = moment([year, month, date]);
					dayAdd = next.diff(moment(request.start.dateTime), 'days');
				} else if (ruleObject.frequency === 'Yearly') {
					limit = 10;
					result.RecurrenceMonthOfYear = moment(
						request.start.dateTime
					).format('MMMM');
					result.RecurrenceType = 'RecursYearly';
					result.RecurrenceDayOfMonth = ruleObject.dayNumbers[0];
					result.RecurrenceInterval = null;
					year = thisMoment.year() + (limit - 1);
					month = thisMoment.month();
					date = thisMoment.date();
					next = moment([year, month, date]);
					dayAdd = next.diff(moment(request.start.dateTime), 'days');
				}
				if (ruleObject.until) {
					result.RecurrenceEndDateOnly = moment(
						ruleObject.until,
						'l'
					).format('YYYY-MM-DD');
				} else {
					result.RecurrenceEndDateOnly = calculateEndDateTime(
						request,
						ruleObject
					);
				}

				return result;

				function dayArrayToIndex(dayArray) {
					var result = 0;
					var map = {
						Sunday: 1,
						Monday: 2,
						Tuesday: 4,
						Wednesday: 8,
						Thursday: 16,
						Friday: 32,
						Saturday: 64,
					};
					for (var i = 0; i < dayArray.length; i++) {
						result += map[dayArray[i]];
					}
					return result;
				}
			}

			function nextMoment(start, days, interval) {
				var sorted = [];
				var dayMap = {
					Sunday: 0,
					Monday: 1,
					Tuesday: 2,
					Wednesday: 3,
					Thursday: 4,
					Friday: 5,
					Saturday: 6,
				};
				if (!days || days.length === 0) {
					return;
				}
				for (var day in dayMap) {
					for (var ii = 0; ii < days.length; ii++) {
						if (days[ii] === day) {
							sorted.push(day);
						}
					}
				}

				var thisDay = start.day();
				for (var i = 0; i < sorted.length; i++) {
					if (dayMap[sorted[i]] > thisDay) {
						//this Week
						return moment(start).add(
							dayMap[sorted[i]] - thisDay,
							'days'
						);
					}
				}
				//next week, add 7 days per interval
				return moment(start)
					.subtract(thisDay - dayMap[sorted[0]], 'days')
					.add(interval * 7, 'days');
			}

			function deleteSourceEvent(event, callback, onError) {
				api.getRecord(
					event.schedule.objectName,
					event.recurringEventID,
					eventInfo
				);
				function eventInfo(data) {
					api.deleteRecord(
						event.schedule.objectName,
						data.Id,
						processDelete
					);
				}

				function processDelete(result) {
					if (result[0] && result[0].errorCode) {
						// Run onError function if one exists
						if (onError) {
							onError(result);
							return;
						}
					}

					if (callback) {
						callback();
					}
				}
			}

			function getPickList(object, field, schedule, callBack) {
				if (schedule.picklist && schedule.picklist[field]) {
					//we've already got the picklist this session
					callBack(schedule.picklist[field]);
				} else if (
					schedule.picklist &&
					schedule.picklist[field] === false
				) {
					//we've checked this session and this field has no picklist
					callBack(false);
				} else {
					api.picklist(object, field, processResult);
				}
				function processResult(data) {
					var message;
					if (data[0] && data[0].errorCode) {
						if (
							data[0].errorCode.indexOf('INVALID_SESSION_ID') !==
							-1
						) {
							message =
								'There was an error contacting Salesforce: ' +
								cleanError(data[0].message);
							utilities.showModal(
								'Operation Failed',
								message,
								'Re-Authorize',
								sessionError,
								'Return To Event',
								confirmError
							);
							return;
						}
					} else if (!data) {
						callBack(data);
						return;
					}
					var i;
					var result = [];
					//initialize picklist if needed
					if (!schedule.picklist) {
						schedule.picklist = {};
					}
					//reset field
					schedule.picklist[field] = false;
					if (data.picklist) {
						//loop to extract only the active values
						for (i in data.picklist) {
							if (data.picklist[i].active) {
								result.push({
									label: data.picklist[i].label,
									value: data.picklist[i].value,
								});
							}
						}
						//set to schedule, so we don't call again this session
						schedule.picklist[field] = {
							restricted: data.restricted,
							list: result,
						};
						callBack(schedule.picklist[field]);
					}
					function confirmError() {
						return;
					}
				}
			}

			function getFieldData(objectName, callback) {
				api.fields(objectName, categorizeFields);
				function categorizeFields(d) {
					if (d[0] && d[0].errorCode) {
						callback(d);
						return;
					}
					var i;
					var titleFields = [];
					var descriptionFields = [];
					var locationFields = [];
					var geocodeFields = [];
					var dateFields = [];
					var dateTimeFields = [];
					var statusFields = [];
					var resourceFields = [];
					var idFields = [];
					var allDayFields = [];
					var tagFields = [];
					var numberFields = [];
					var contactFields = [];
					var picklistFields = [];
					var customFields = [];
					var urlFields = [];
					var textFields = [];
					var result = {};
					for (i in d) {
						if (d[i].type === 'location') {
							geocodeFields.push(d[i]);
						}
						if (d[i].type === 'date') {
							dateFields.push(d[i]);
						}
						if (d[i].type === 'datetime') {
							dateTimeFields.push(d[i]);
						}
						if (d[i].type === 'textarea') {
							tagFields.push(d[i]);
						}
						if (
							d[i].type === 'string' ||
							d[i].type === 'combobox' ||
							d[i].type === 'picklist' ||
							d[i].type === 'textarea'
						) {
							titleFields.push(d[i]);
							statusFields.push(d[i]);
							descriptionFields.push(d[i]);
							locationFields.push(d[i]);
							textFields.push(d[i]);
						}
						if (
							d[i].type === 'string' ||
							d[i].type === 'combobox' ||
							d[i].type === 'picklist' ||
							d[i].type === 'textarea' ||
							d[i].type === 'multipicklist'
						) {
							resourceFields.push(d[i]);
						}
						if (
							d[i].type === 'reference' &&
							d[i].relationshipName
						) {
							idFields.push(d[i]);
						}
						if (d[i].type === 'boolean') {
							allDayFields.push(d[i]);
						}
						if (
							d[i].type === 'double' ||
							d[i].type === 'currency' ||
							d[i].type === 'percent'
						) {
							numberFields.push(d[i]);
						}
						if (d[i].type === 'email' || d[i].type === 'phone') {
							contactFields.push(d[i]);
						}
						if (
							d[i].type === 'picklist' ||
							d[i].type === 'multipicklist'
						) {
							picklistFields.push(d[i]);
						}
						if (
							d[i].type === 'string' ||
							d[i].type === 'combobox' ||
							d[i].type === 'textarea' ||
							d[i].type === 'url'
						) {
							customFields.push(d[i]);
						}
						if (
							d[i].type === 'string' ||
							d[i].type === 'combobox' ||
							d[i].type === 'textarea' ||
							d[i].type === 'url'
						) {
							urlFields.push(d[i]);
						}
					}

					var owner = {
						apiName: 'Owner.Name',
						label: 'Owner',
					};

					resourceFields.push(owner);

					resourceFields.sort(compare);

					//

					result.dateFields = dateFields;
					result.dateTimeFields = dateTimeFields;
					result.titleFields = titleFields;
					result.statusFields = statusFields;
					result.resourceFields = resourceFields;
					result.descriptionFields = descriptionFields;
					result.locationFields = locationFields;
					result.geocodeFields = geocodeFields;
					result.idFields = idFields;
					result.allDayFields = allDayFields;
					result.tagFields = tagFields;
					result.numberFields = numberFields;
					result.contactFields = contactFields;
					result.picklistFields = picklistFields;
					result.customFields = customFields;
					result.urlFields = urlFields;
					result.textFields = textFields;

					api.recordTypes(objectName, processRecordTypes);

					function processRecordTypes(data) {
						var i;
						if (data && data[0] && data[0].errorCode) {
							// callback(data);
							// return;
							result.recordTypes = false;
						} else if (data) {
							result.recordTypes = data;
						}
						callback(result);
					}

					function compare(a, b) {
						if (a.label < b.label) {
							return -1;
						} else if (a.label > b.label) {
							return 1;
						} else {
							return 0;
						}
					}
				}
			}

			function getSchedules(callback, sourceTemplate) {
				var config = seedcodeCalendar.get('config');
				var sources = seedcodeCalendar.get('sources');
				var schedule;
				var schedules = [];
				for (var i = 0; i < sources.length; i++) {
					if (sources[i].sourceTypeID === sourceTemplate.id) {
						schedule = setSchedule(sources[i]);
						schedules.push(schedule);
					}
				}

				api.saveSchedules(schedules);

				callback(schedules, sourceTemplate);
				//Grab the external settings file and run the apply settings function

				function setSchedule(schedule) {
					//Clean schedule data
					schedule.sourceID = schedule.id;

					// Set source specific custom field properties
					if (schedule.customFields) {
						for (var property in schedule.customFields) {
							schedule.customFields[property].mutateInput =
								customFieldMutateInput;
						}
					}

					calendarIO.cleanSchedule(schedule, sourceTemplate);

					return schedule;
				}
			}

			function disableSchedule(scheduleID) {
				manageFetch.clear(scheduleID);
			}

			function getUnscheduled(callbackFunc, schedule) {
				var config = seedcodeCalendar.get('config');
				var connection = createConnection(schedule);
				var fieldMap = connection.fieldMap;

				if (
					!schedule.allowUnscheduled ||
					schedule.unusedMap?.unscheduled ||
					!schedule.fieldMap.unscheduled
				) {
					callbackFunc();
					return;
				}

				//make sure we have field info for this object if includeFilters is specified
				if (!api.settings.fields[schedule.objectName]) {
					api.fields(schedule.objectName, function (result) {
						getUnscheduled(callbackFunc, schedule, true);
					});
					return;
				}

				var callback = manageFetch.create(
					schedule.id,
					'unscheduled',
					callbackFunc
				).confirm;

				var requests = [
					{
						unscheduled: '=true',
					},
				];

				//get events
				// Run before events fetched action
				api.findRecords(
					connection.objectName,
					processEvents,
					requests,
					schedule.id,
					null
				);
				function processEvents(result) {
					if (callback) {
						callback(mutateSalesforceEvents(result, schedule));
					}
				}
			}

			function getMapEvents(bounds, callbackFunc, schedule, onError) {
				if (schedule.allowTextFieldMap?.geocode) {
					utilities.showMessage(
						`Limit to map boundary requires a geocode field on calendar: ${schedule.name}`,
						0,
						8000,
						'error'
					);
					callbackFunc();
					return;
				}
				const geoFieldPrefix = schedule.fieldMap.geocode?.substring(
					0,
					schedule.fieldMap.geocode.length - 1
				);
				const request = encodeURIComponent(
					`WHERE ( ${geoFieldPrefix}Latitude__s >= ${bounds.bottom} AND ${geoFieldPrefix}Latitude__s <= ${bounds.top} AND ${geoFieldPrefix}Longitude__s >= ${bounds.left} AND ${geoFieldPrefix}Longitude__s <= ${bounds.right} )`
				);

				getEvents(
					null,
					null,
					null,
					callbackFunc,
					schedule,
					null,
					onError,
					request
				);
			}

			function getEvents(
				start,
				end,
				timezone,
				callbackFunc,
				schedule,
				rerun,
				onError,
				requestOverride
			) {
				var config = seedcodeCalendar.get('config');
				var connection = createConnection(schedule);
				var fieldMap = connection.fieldMap;
				var statuses = seedcodeCalendar.get('statuses');
				var resources = seedcodeCalendar.get('resources');
				var activeStatuses = [];
				var activeResources = [];
				var clause = false;
				var i;
				var textFilter = seedcodeCalendar.get('textFilters');

				var view = seedcodeCalendar.get('view');

				//make sure we have field info for this object if includeFilters is specified
				if (
					!api.settings.fields[schedule.objectName] &&
					schedule.includeFilters &&
					rerun !== true
				) {
					api.fields(schedule.objectName, function (result) {
						getEvents(
							start,
							end,
							timezone,
							callbackFunc,
							schedule,
							true
						);
					});
					return;
				}

				var callback = manageFetch.create(
					schedule.id,
					'event',
					callbackFunc
				).confirm;

				for (i = 0; i < resources.length; i++) {
					if (
						resources[i].status &&
						resources[i].status.selected &&
						!resources[i].isFolder
					) {
						activeResources.push(
							"'" +
								(apiType === 'fbk'
									? encodeURIComponent(
											resources[i].name.replace(
												/'/g,
												"\\'"
											)
										)
									: resources[i].name.replace(/'/g, "\\'")) +
								"'"
						);
					}
				}
				for (i = 0; i < statuses.length; i++) {
					if (
						statuses[i].status &&
						statuses[i].status.selected &&
						!statuses[i].isFolder
					) {
						activeStatuses.push(
							"'" +
								(apiType === 'fbk'
									? encodeURIComponent(
											statuses[i].name.replace(
												/'/g,
												"\\'"
											)
										)
									: statuses[i].name.replace(/'/g, "\\'")) +
								"'"
						);
					}
				}

				if (
					(schedule.requireResourceFilters &&
						activeResources.length === 0) ||
					(schedule.requireFilters &&
						(!textFilter || textFilter.length === 0) &&
						activeResources.length === 0 &&
						activeStatuses.length === 0)
				) {
					//show toast message that filters are required.
					utilities.showMessage(
						'There are no required filters selected. Some selected calendars are configured to not show items in this situation. <a>Read more...</a>',
						0,
						5000,
						'message',
						function () {
							utilities.help('Speed', '137-speed');
						}
					);
					callback(false);
					return;
				}

				if (schedule.includeFilters) {
					var resourceIn = '';
					var statusIn = '';
					var fieldType = '';
					var statement;
					var fields;
					if (
						!schedule.useAssignedResources &&
						schedule.includeFilters &&
						activeResources.length > 0
					) {
						statement = ' IN ';
						fields = api.settings.fields[schedule.objectName];
						for (var ii = 0; ii < fields.length; ii++) {
							if (
								fields[ii].apiName ===
								schedule.fieldMap.resource
							) {
								fieldType = fields[ii].type;
							}
						}
						if (fieldType && fieldType === 'multipicklist') {
							statement = ' INCLUDES ';
						}
						if (
							(schedule.unusedMap &&
								!schedule.unusedMap.resource &&
								schedule.fieldMap.resource) ||
							schedule.fieldMap.resource
						) {
							resourceIn =
								activeResources.length > 0
									? fieldMap.resource +
										statement +
										'(' +
										activeResources.join(',') +
										')'
									: '';
						}
					}

					if (schedule.includeFilters && activeStatuses.length > 0) {
						statement = ' IN ';
						fields = fields
							? fields
							: api.settings.fields[schedule.objectName];
						for (var iii = 0; iii < fields.length; iii++) {
							if (
								fields[iii].apiName === schedule.fieldMap.status
							) {
								fieldType = fields[iii].type;
							}
						}
						if (fieldType && fieldType === 'multipicklist') {
							statement = ' INCLUDES ';
						}
						if (
							(schedule.unusedMap &&
								!schedule.unusedMap.status &&
								schedule.fieldMap.status) ||
							schedule.fieldMap.status
						) {
							statusIn =
								activeStatuses.length > 0
									? fieldMap.status +
										statement +
										'(' +
										activeStatuses.join(',') +
										')'
									: '';
						}
					}

					//if none filter is selected we want to add a clause to pick up the nulls
					if (
						resourceIn.indexOf("'" + config.noFilterLabel + "'") !==
						-1
					) {
						resourceIn =
							'(' +
							resourceIn +
							' OR ' +
							fieldMap.resource +
							' = NULL)';
					}
					if (
						statusIn.indexOf("'" + config.noFilterLabel + "'") !==
						-1
					) {
						statusIn =
							'(' +
							statusIn +
							' OR ' +
							fieldMap.status +
							' = NULL)';
					}

					if (resourceIn || statusIn) {
						clause =
							' AND (' +
							resourceIn +
							(resourceIn && statusIn
								? ' AND ' + statusIn
								: statusIn) +
							')';
					}
				}

				var unusedMap = schedule.unusedMap;
				if (fieldMap.end === fieldMap.start && fieldMap.start) {
					fieldMap.end = '';
				}

				//if all Day is not specified for this object in settings/field mapping then we want to format as date only.
				var sfStart, sfEnd;

				if (
					fieldMap.allDay ||
					(unusedMap.allDay && schedule.allowAllDay)
				) {
					sfStart = moment(start).subtract(1, 'days').format();
					sfEnd = moment(end).add(1, 'days').format(); //Added a day to fix an issue with different timezones on all day events: Case HS829
				} else {
					sfStart = moment(start)
						.subtract(1, 'days')
						.format('YYYY-MM-DD');
					sfEnd = moment(end).add(1, 'days').format('YYYY-MM-DD'); //Added a day to fix an issue with different timezones on all day events: Case HS829
				}

				//create two objects in a JSON array, each one is a Find Request
				var requests = [
					{
						start: '< ' + sfEnd,
						end: '> ' + sfStart,
					},
					{
						start: sfStart + '...' + sfEnd,
					},
				];

				if (
					view.name === 'basicHorizon' &&
					config.hideSalesforceRepetitions &&
					(connection.objectName === 'Task' ||
						connection.objectName === 'Event')
				) {
					if (!clause) {
						clause = '';
					}
					clause += ' AND (RecurrenceActivityId = null)';
				}
				// Replace all spaces with plus for fbk
				if (clause && apiType === 'fbk') {
					clause = clause.replace(/ /g, '+');
				}

				//get events
				if (schedule.useAssignedResources) {
					const select =
						'SELECT+Id,ServiceResource.Id,ServiceResource.Name,ServiceAppointment.Id+FROM+AssignedResource';
					const where = `WHERE+ServiceAppointment.SchedStartTime+<+${sfEnd}+AND+ServiceAppointment.SchedEndTime+>+${sfStart}`;
					const resourceQuery = `${select}+${where}`;

					// Run before events fetched action
					const actionCallbacks = {
						confirm: function () {
							api.findServiceRecords(
								connection.objectName,
								processEvents,
								requests,
								schedule.id,
								clause,
								resourceQuery
							);
						},
						cancel: function () {
							callback(mutateSalesforceEvents([], schedule));
						},
					};

					const actionResult = calendarIO.runEventActions(
						'beforeEventsFetched',
						{schedule: schedule},
						true,
						actionCallbacks,
						null
					);

					if (!actionResult) {
						return;
					}

					api.findServiceRecords(
						connection.objectName,
						processEvents,
						requests,
						schedule.id,
						clause,
						resourceQuery
					);
				} else {
					// Run before events fetched action
					const actionCallbacks = {
						confirm: function () {
							api.findRecords(
								connection.objectName,
								processEvents,
								requests,
								schedule.id,
								clause,
								requestOverride
							);
						},
						cancel: function () {
							callback(mutateSalesforceEvents([], schedule));
						},
					};

					const actionResult = calendarIO.runEventActions(
						'beforeEventsFetched',
						{schedule: schedule},
						true,
						actionCallbacks,
						null
					);

					if (!actionResult) {
						return;
					}

					api.findRecords(
						connection.objectName,
						processEvents,
						requests,
						schedule.id,
						clause,
						requestOverride
					);
				}

				function processEvents(result) {
					if (result[0] && result[0].errorCode) {
						// Run onError function if one exists
						if (onError) {
							onError(result);
						}
						//Run callback so we don't keep waiting for events
						callback();
						return;
					}
					//callback is the built in full calendar event callback. This will write the events we just got to the view.
					callback(mutateSalesforceEvents(result, schedule));
				}
			}

			function customFieldMutateInput(item, fieldDefinition) {
				var result;
				if (fieldDefinition.formatas === 'select') {
					if (!item) {
						result = [];
					} else if (Array.isArray(item)) {
						result = item;
					} else {
						try {
							result = item.split(';');
							if (!Array.isArray(result)) {
								result = [item];
							}
						} catch (error) {
							result = [item];
						}
					}
					calendarIO.replaceWithNoFilterValue(result);
				} else {
					result = item;
				}
				return result;
			}

			function customFieldMutateOutput(item, customField, schedule) {
				var config = seedcodeCalendar.get('config');

				if (customField && customField.formatas === 'date') {
					//Test for date time field vs date field
					if (customField.dataFormat === 'datetime') {
						return item
							? moment(item).format(schedule.fileTimestampFormat)
							: '';
					} else {
						return item
							? moment(item).format(schedule.fileDateFormat)
							: '';
					}
				} else if (
					customField &&
					customField.formatas === 'timestamp'
				) {
					return item
						? moment(item).format(schedule.fileTimestampFormat)
						: '';
				} else if (Array.isArray(item)) {
					calendarIO.removeNoFilterValue(item);
					return item.join(';');
				} else {
					return item;
				}
			}

			function mutateSalesforceEvents(events, schedule) {
				const hookResult = calendarIO.mutateHook();
				if (!hookResult) {
					return [];
				}

				var i = 0;
				var output = [];
				var connection = createConnection(schedule);
				var fieldMap = connection.fieldMap;
				var unusedMap = schedule.unusedMap;
				var customFields = schedule.customFields;

				var view = seedcodeCalendar.get('view');
				var start = view.intervalStart;
				var end = view.intervalEnd;
				var dayRange = end.diff(start, 'days');

				//Determine custom object and format to use for all day events
				var isCustomObject = schedule.objectName
					? schedule.objectName.slice(-3) === '__c'
					: false;
				var allDayFormat =
					isCustomObject && schedule.allowAllDay ? '' : 'YYYY-MM-DD';

				for (i = 0; i < events.length; i++) {
					//don't push events where eventID = recurringEventID, these aren't rendered in SF either.
					if (events[i].Id === events[i].RecurrenceActivityId) {
						//source repeater event
					} else {
						output.push(mutate(events[i]));
					}
				}

				return output;

				function mutate(event) {
					var OwnerId = event.OwnerId;
					var recurrenceId = event.RecurrenceActivityId
						? event.RecurrenceActivityId.substring(0, 15)
						: '';
					event.eventSource = schedule.id;
					event.schedule = schedule;

					//transform property names using our field mapping
					var c = 0;
					var props = Object.getOwnPropertyNames(fieldMap);
					var fmProp = '';
					var sourceProp = '';
					var newEvent = {};
					for (c in props) {
						fmProp = props[c];
						sourceProp = fieldMap[fmProp];
						//If mapped field is not set just continue as there is no data to mutate
						if (!sourceProp) {
							continue;
						}

						if (sourceProp.indexOf('.') !== -1) {
							sourceProp = sourceProp.split('.')[0];
						}

						if (event[sourceProp] !== null) {
							newEvent[fmProp] = event[sourceProp];
						} else {
							newEvent[fmProp] = null;
						}
					}

					// Set assigned resources
					if (event.schedule.useAssignedResources) {
						newEvent.resource = event.resource;
					}

					// only store the 15 left characters of the SF Id as that's the actual key.
					// unless this is using assigned resources and using the service appointments api
					if (!event.schedule.useAssignedResources) {
						newEvent.eventID = newEvent.eventID.substring(0, 15);
					}
					if (
						newEvent.contactID &&
						typeof newEvent.contactID === 'string'
					) {
						newEvent.contactID = newEvent.contactID.substring(
							0,
							15
						);
					}
					if (
						newEvent.projectID &&
						typeof newEvent.projectID === 'string'
					) {
						newEvent.projectID = newEvent.projectID.substring(
							0,
							15
						);
					}
					if (
						newEvent.resourceID &&
						typeof newEvent.resourceID === 'string'
					) {
						newEvent.resourceID = newEvent.resourceID.substring(
							0,
							15
						);
					}

					//set the unmapped salesforce properties we want
					newEvent.OwnerId = OwnerId;

					//populate the related object properties for drawer and pop-up display
					//We read Who and What a little differently as they can be related to different objects
					var contactObject;
					var contactSearch;
					var contactDisplay;
					var contactObjectDisplay;
					var projectObject;
					var projectObjectDisplay;
					var projectSearch;
					var projectDisplay;
					var resourceObject;
					var resourceObjectDisplay;
					var resourceSearch;
					var resourceDisplay;
					var url;
					var urlArray;
					var properties;

					var labelMapOverride = event.labelMapOverride
						? event.labelMapOverride
						: {};

					//contactID
					if (fieldMap.contactID === 'WhoId' && event.Who) {
						url = event.Who.attributes.url;
						urlArray = url.split('/');
						contactObject = urlArray[urlArray.length - 2];
					} else if (fieldMap.contactID === 'WhatId' && event.What) {
						url = event.What.attributes.url;
						urlArray = url.split('/');
						contactObject = urlArray[urlArray.length - 2];
					} else if (
						fieldMap.contactID &&
						schedule.contactObjects &&
						Object.keys(schedule.contactObjects).length === 1
					) {
						properties = Object.keys(schedule.contactObjects);
						contactObject =
							schedule.contactObjects[properties[0]].object;
					} else {
						contactObject = '';
					}
					//now projectID
					if (fieldMap.projectID === 'WhatId' && event.What) {
						url = event.What.attributes.url;
						urlArray = url.split('/');
						projectObject = urlArray[urlArray.length - 2];
					} else if (fieldMap.projectID === 'WhoId' && event.Who) {
						url = event.Who.attributes.url;
						urlArray = url.split('/');
						projectObject = urlArray[urlArray.length - 2];
					} else if (
						fieldMap.projectID &&
						schedule.projectObjects &&
						Object.keys(schedule.projectObjects).length === 1
					) {
						properties = Object.keys(schedule.projectObjects);
						projectObject =
							schedule.projectObjects[properties[0]].object;
					} else {
						projectObject = '';
					}
					//now resourceID
					if (fieldMap.resourceID === 'WhatId' && event.What) {
						url = event.What.attributes.url;
						urlArray = url.split('/');
						resourceObject = urlArray[urlArray.length - 2];
					} else if (fieldMap.resourceID === 'WhoId' && event.Who) {
						url = event.Who.attributes.url;
						urlArray = url.split('/');
						resourceObject = urlArray[urlArray.length - 2];
					} else if (
						fieldMap.resourceID &&
						schedule.resourceObjects &&
						Object.keys(schedule.resourceObjects).length === 1
					) {
						properties = Object.keys(schedule.resourceObjects);
						resourceObject =
							schedule.resourceObjects[properties[0]].object;
					} else {
						resourceObject = '';
					}

					//retrieve search and display Fields from schedule.contactObject
					for (c in schedule.contactObjects) {
						if (
							schedule.contactObjects[c].object === contactObject
						) {
							contactSearch =
								schedule.contactObjects[c].searchField;
							contactDisplay =
								schedule.contactObjects[c].displayField;
							contactObjectDisplay =
								schedule.contactObjects[c].objectDisplay;
						}
					}

					//retrieve search Field from schedule.contactObject
					for (c in schedule.projectObjects) {
						if (
							schedule.projectObjects[c].object === projectObject
						) {
							projectSearch =
								schedule.projectObjects[c].searchField;
							projectDisplay =
								schedule.projectObjects[c].displayField;
							projectObjectDisplay =
								schedule.projectObjects[c].objectDisplay;
						}
					}

					//retrieve search Field from schedule.contactObject
					for (c in schedule.resourceObjects) {
						if (
							schedule.resourceObjects[c].object ===
							resourceObject
						) {
							resourceSearch =
								schedule.resourceObjects[c].searchField;
							resourceDisplay =
								schedule.resourceObjects[c].displayField;
							resourceObjectDisplay =
								schedule.resourceObjects[c].objectDisplay;
						}
					}

					//append to object
					newEvent.projectObjectDisplay = projectObjectDisplay;
					newEvent.projectDisplay = projectDisplay;
					newEvent.projectSearch = projectSearch;
					newEvent.projectObject = projectObject;
					newEvent.resourceObjectDisplay = resourceObjectDisplay;
					newEvent.resourceDisplay = resourceDisplay;
					newEvent.resourceSearch = resourceSearch;
					newEvent.resourceObject = resourceObject;
					newEvent.contactObjectDisplay = contactObjectDisplay;
					newEvent.contactDisplay = contactDisplay;
					newEvent.contactSearch = contactSearch;
					newEvent.contactObject = contactObject;

					labelMapOverride.contactName = contactObjectDisplay;
					labelMapOverride.projectName = projectObjectDisplay;
					newEvent.labelMapOverride = labelMapOverride;

					var title = fieldMap.title ? fieldMap.title : '';
					var fields = api.mapToArray(schedule);
					var fieldResult;

					//clean up entry
					title = title
						.replace(/[\n\r]/g, '')
						.replace(/, +/g, ',')
						.replace(/ +,/g, ',');

					var re;
					var resultArray = [];
					var titleArray = title ? title.split(',') : [];
					var populated;
					var matchResult;
					for (var i = 0; i < titleArray.length; i++) {
						populated = false;
						for (var ii = 0; ii < fields.length; ii++) {
							var thisField = fields[ii];
							re = new RegExp(thisField, 'g');
							if (
								titleArray[i].indexOf(thisField) !== -1 &&
								thisField !== 'Id'
							) {
								fieldResult = formatDateTime(
									getFieldValue(thisField, event)
								);

								if (fieldResult) {
									populated = true;
									titleArray[i] = titleArray[i].replace(
										re,
										fieldResult
									);
								} else {
									titleArray[i] = titleArray[i].replace(
										re,
										''
									);
								}
							}
						}
						matchResult = addMatch(titleArray[i]);
						if (populated) {
							resultArray.push(matchResult);
						} else {
							// If there is not field value there may still be content like css
							// So just append to the end of the previous match
							if (!resultArray.length) {
								resultArray[0] = '';
							}
							resultArray[
								resultArray.length > 0
									? resultArray.length - 1
									: 0
							] += matchResult;
						}
					}

					function addMatch(line) {
						var eSplit = line.split('</');
						var matchSplit;
						var match;
						var value;
						var matchWord;
						var matchStyle;
						var thisMatch;
						var span;
						var re;
						if (eSplit.length === 1) {
							return line;
						} else if (eSplit.length > 1) {
							for (var i = 0; i < eSplit.length; i++) {
								matchSplit = eSplit[i].split('dbk-match=');
								if (matchSplit.length > 1) {
									match = matchSplit[1];
									match = match.split('"');
									if (match.length !== 3) {
										return line;
									}
									match = match[1];
									eSplit[i] = eSplit[i]
										.split('dbk-match="' + match + '"')
										.join('');
									value = eSplit[i].split('>');

									if (value.length === 1) {
										return line;
									}
									value = value[1];
									thisMatch = match.split('?');
									if (thisMatch.length === 1) {
										return line;
									}
									matchWord = thisMatch[0].trim();
									if (matchWord.split("'").length === 3) {
										matchWord = matchWord.split("'")[1];
									} else if (
										matchWord.split('"').length === 3
									) {
										matchWord = matchWord.split('"')[1];
									} else {
										return line;
									}
									matchStyle = thisMatch[1].trim();
									span =
										'<span style="' +
										matchStyle +
										'" >' +
										matchWord +
										'</span>';
									re = new RegExp(matchWord, 'g');
									eSplit[i] = eSplit[i].replace(re, span);
								}
							}
						}
						var result = eSplit.join('</');
						return result;
					}

					function getFieldValue(field, event) {
						field = field.trim();
						var config = seedcodeCalendar.get('config');
						var fieldArray = field.split('.');
						var result;
						if (fieldArray.length === 1) {
							result = event[fieldArray[0]]
								? event[fieldArray[0]]
								: '';
						} else if (fieldArray.length === 2) {
							result = event[fieldArray[0]];
							result = result
								? event[fieldArray[0]][fieldArray[1]]
								: '';
							result = result ? result : '';
						} else if (fieldArray.length === 3) {
							result = event[fieldArray[0]];
							result = result
								? event[fieldArray[0]][fieldArray[1]]
								: '';
							result = result
								? event[fieldArray[0]][fieldArray[1]][
										fieldArray[2]
									]
								: '';
							result = result ? result : '';
						}
						//pad semi-colons for resources
						if (fieldMap.resource === field) {
							var semicolonSplit = result.split(';');
							if (semicolonSplit.length === 1) {
								return result;
							} else {
								for (
									var i = 0;
									i < semicolonSplit.length;
									i++
								) {
									semicolonSplit[i] =
										semicolonSplit[i].trim();
								}
								return semicolonSplit.join('; ');
							}
						} else {
							return result;
						}
					}

					function formatDateTime(value) {
						var dateSplit;
						if (!value || typeof value !== 'string') {
							//empty or not a string
							return value;
						}
						//test if not a date and not a date time
						var dateTest = new RegExp(/^\d\d\d\d-\d\d-\d\d$/);
						var dateTimeTest = new RegExp(
							/^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\.\d\d\d\+\d\d\d\d$/
						);
						if (
							!value.match(dateTest) &&
							!value.match(dateTimeTest)
						) {
							return value;
						} else if (
							value.match(dateTest) &&
							!value.match(dateTimeTest)
						) {
							return moment(value).format('L');
						}
						var isMoment = moment(value);
						var momentTest = moment(isMoment.format());
						if (isMoment.isSame(momentTest)) {
							if (
								(event.schedule.allowAllDay &&
									event.IsAllDayEvent) ||
								!event.schedule.allowAllDay
							) {
								return moment(
									moment.utc(momentTest).format('YYYY-MM-DD')
								).format('L');
							} else {
								var config = seedcodeCalendar.get('config');
								var newTime = $.fullCalendar.createTimezoneTime(
									moment(value),
									false,
									true
								);
								return newTime.format(config.timeFormat);
							}
						} else {
							return value;
						}
					}

					for (var iii = 0; iii < resultArray.length; iii++) {
						if (iii === 0) {
							newEvent.title = resultArray[iii];
						} else if (resultArray[iii]) {
							newEvent.title += '\n' + resultArray[iii];
						}
					}

					if (newEvent.title) {
						//honor salesforce method for putting returns in formula fields
						newEvent.title = newEvent.title.replace(/<br>/g, '\n');
					}

					event = newEvent;

					event.eventSource = schedule.id;
					event.schedule = schedule;

					//convert start and end to moment
					if (schedule.unusedMap && schedule.unusedMap.allDay) {
						fieldMap.allDay = '';
					}

					if (event.start) {
						if (event.allDay) {
							event.start = moment(
								moment.utc(event.start).format(allDayFormat)
							);
						} else if (fieldMap.allDay === '') {
							if (schedule.allowAllDay) {
								event.start = $.fullCalendar.createTimezoneTime(
									moment.utc(event.start),
									false,
									true
								);
							} else {
								event.start = moment(
									moment.utc(event.start).format('YYYY-MM-DD')
								);
							}
						} else {
							event.start = $.fullCalendar.createTimezoneTime(
								moment.utc(event.start),
								false,
								true
							);
						}
					}

					if (event.end) {
						if (event.allDay) {
							event.end = moment(
								moment.utc(event.end).format(allDayFormat)
							);
						} else if (fieldMap.allDay === '') {
							if (schedule.allowAllDay) {
								event.end = $.fullCalendar.createTimezoneTime(
									moment.utc(event.end),
									false,
									true
								);
							} else {
								event.end = moment(
									moment.utc(event.end).format('YYYY-MM-DD')
								);
							}
						} else {
							event.end = $.fullCalendar.createTimezoneTime(
								moment.utc(event.end),
								false,
								true
							);
						}
					}

					//need to abstact this for any objects not marked allDay
					if (!schedule.allowAllDay) {
						event.allDay = true;
					}

					//inject values into arrays as that's what DayBack wants
					if (event.status) {
						event.status = event.status.split(';');
					} else {
						event.status = [];
					}

					//grab the non attribute value
					var searchField;
					var propertyMatch;
					if (event.contactName) {
						propertyMatch = false;
						searchField = event.schedule.fieldMap.contactName;
						for (var contactProperty in event.contactName) {
							if (
								contactProperty ===
								(searchField.includes('.')
									? searchField.split('.')[1]
									: searchField)
							) {
								event.contactName = [
									event.contactName[contactProperty],
								];
								propertyMatch = true;
								break;
							}
						}
						if (!propertyMatch) {
							event.contactName = [];
						}
					} else {
						event.contactName = [];
					}

					if (event.projectName) {
						propertyMatch = false;
						searchField = event.schedule.fieldMap.projectName;
						for (var projectProperty in event.projectName) {
							if (
								projectProperty ===
								(searchField.includes('.')
									? searchField.split('.')[1]
									: searchField)
							) {
								event.projectName = [
									event.projectName[projectProperty],
								];
								propertyMatch = true;
								break;
							}
						}
						if (!propertyMatch) {
							event.contactName = [];
						}
					} else {
						event.projectName = [];
					}

					if (event.contactID) {
						event.contactID = [event.contactID];
					} else {
						event.contactID = [];
					}

					if (event.projectID) {
						event.projectID = [event.projectID];
					} else {
						event.projectID = [];
					}

					if (event.resourceID) {
						event.resourceID = [event.resourceID];
					} else {
						event.resourceID = [];
					}

					if (event.resource) {
						if (fieldMap?.resource?.includes('.')) {
							//is related field
							var property = fieldMap.resource.split('.')[1];
							event.resource =
								event.resource[property].split(';');
						} else {
							event.resource = event.resource.split(';');
						}
					} else {
						event.resource = [];
					}

					if (event.geocode) {
						// Check if string geocode
						if (schedule.allowTextFieldMap?.geocode) {
							const geocodeParts = event.geocode.split(',');
							event.geocode = {
								lat: parseFloat(geocodeParts[0]?.trim()),
								lng: parseFloat(geocodeParts[1]?.trim()),
							};
							if (
								isNaN(event.geocode.lat) ||
								isNaN(event.geocode.lng)
							) {
								event.geocode = '';
							}
						} else {
							event.geocode = {
								lat: event.geocode.latitude,
								lng: event.geocode.longitude,
							};
						}
					}

					//return mulated event
					return calendarIO.cleanEvent(event);
				}
			}

			//Edit Events

			function editEvent(
				event,
				revertObject,
				revertFunc,
				changes,
				editID,
				callback,
				schedule
			) {
				var fieldMap = schedule.fieldMap;
				var relatedValueMap = schedule.relatedValueMap;
				var connection = createConnection(schedule);
				var source;
				var config = seedcodeCalendar.get('config');
				var customFields = schedule.customFields;
				var allDay = changes.hasOwnProperty('allDay')
					? changes.allDay
					: event.allDay;
				var translatedProperty;
				var editObj = {};
				var mes;
				var modified;
				var ownerId;
				var ownerChanged;
				var result;
				var element = seedcodeCalendar.get('element');
				var newItem = false;
				var checkFieldForNoFilter;

				//Determine custom object and format to use for all day events
				var isCustomObject = schedule.objectName
					? schedule.objectName.slice(-3) === '__c'
					: false;
				var allDayFormat =
					isCustomObject && schedule.allowAllDay ? '' : 'YYYY-MM-DD';

				//begin validation
				//if end and start are mapped to the same field, then just map from start
				if (fieldMap.end === fieldMap.start && fieldMap.start) {
					fieldMap.end = '';
				}

				//if the source is set to not allow times, then clear any mapping to all Day.
				if (!schedule.allowAllDay) {
					fieldMap.allDay = '';
				}
				//if allDay is unused, then clear from map.
				if (schedule.unusedMap && schedule.unusedMap.allDay) {
					fieldMap.allDay = '';
				}

				if (
					fieldMap.allDay === '' &&
					changes.allDay &&
					schedule.allowAllDay
				) {
					//allDay not mapped or disabled and times allowed, but they're trying to drag to allDay so error.
					var calendarName =
						event.schedule.name.substring(0, 1).toUpperCase() +
						event.schedule.name.substring(1);
					result = [
						{
							errorCode:
								"'All Day Event' is currently disabled for " +
								calendarName +
								'.',
							message:
								"'All Day Event' is currently disabled for " +
								calendarName +
								'.',
						},
					];
					resultCallback(result);
					return;
				}

				//if we have no ending mapped or ending is marked unused, then prevent a change in duration via drag
				var unused = false;
				if (schedule.unusedMap) {
					if (schedule.unusedMap.end) {
						unused = true;
					}
				}

				for (var property in fieldMap) {
					//Don't send unused / unmapped items to edit object
					if (!fieldMap[property] || schedule.unusedMap?.[property]) {
						continue;
					}

					//If the field is mapped and it has a change property, or if it is
					if (
						// property !== "eventID" &&
						property !== 'title' &&
						changes.hasOwnProperty(property)
					) {
						translatedProperty = fieldMap[property];
						//format start and stop as needed
						if (
							changes[property] !== null &&
							(property === 'start' || property === 'end')
						) {
							if (!fieldMap.allDay) {
								if (schedule.allowAllDay) {
									//if all day not mapped, but time allowed, then this is a timed event
									editObj[translatedProperty] = $.fullCalendar
										.timezoneTimeToLocalTime(
											moment(changes[property]),
											allDay
										)
										.format();
								} else {
									editObj[translatedProperty] = moment(
										changes[property]
									).format(allDayFormat);
								}
							} else if (allDay) {
								editObj[translatedProperty] = moment(
									changes[property]
								).format(allDayFormat);
							} else {
								editObj[translatedProperty] = $.fullCalendar
									.timezoneTimeToLocalTime(
										moment(changes[property]),
										allDay
									)
									.format();
							}
						} else if (
							property === 'resource' ||
							property === 'status'
						) {
							editObj[translatedProperty] =
								changes[property].slice(); //Using slice here to clone the array
							//Remove "none" label from resources so we don't write it back to the source
							for (
								var i = 0;
								i < editObj[translatedProperty].length;
								i++
							) {
								if (
									editObj[translatedProperty][i] ===
									config.noFilterLabel
								) {
									editObj[translatedProperty][i] = '';
								}
							}
						} else if (property === 'geocode') {
							if (schedule.allowTextFieldMap?.geocode) {
								editObj[translatedProperty] = !changes[property]
									? changes[property]
									: `${changes[property].lat},${changes[property].lng}`;
							} else {
								const rootField = translatedProperty.replace(
									'__c',
									''
								);
								editObj[`${rootField}__latitude__s`] = !changes[
									property
								]
									? changes[property]
									: changes[property].lat;
								editObj[`${rootField}__longitude__s`] =
									!changes[property]
										? changes[property]
										: changes[property].lng;
							}
						} else if (property === 'tags') {
							editObj[translatedProperty] =
								calendarIO.packageTagsOutput(event[property]);
						} else if (customFields && customFields[property]) {
							if (
								customFields[property].formatas === 'select' &&
								customFields[property].allowMultiple
							) {
								// Check if multi-select list contains none

								for (
									var i = 0;
									i < changes[property].length;
									i++
								) {
									if (
										changes[property][i] ===
										config.noFilterLabel
									) {
										checkFieldForNoFilter = property;
										break;
									}
								}
							}
							editObj[translatedProperty] =
								calendarIO.customFieldOutputTransform(
									property,
									changes[property],
									customFields,
									schedule,
									customFieldMutateOutput
								);
						} else if (Array.isArray(changes[property])) {
							editObj[translatedProperty] =
								changes[property].slice(); //Use slice here to clone the array rather than using a reference
						} else {
							editObj[translatedProperty] = changes[property];
						}
					}
				}

				//if we have changes properties that aren't mapped, then use those property names for request
				for (var change in changes) {
					if (
						fieldMap[change] === undefined &&
						change != 'eventSource'
					) {
						if (!editObj[change]) {
							editObj[change] = changes[change];
						}
					}
				}

				//Set eventID in changes so we know if it is a new event or not
				editObj[fieldMap.eventID] = event.eventID;

				//arrays to string
				if (editObj[fieldMap.contactID]) {
					if (editObj[fieldMap.contactID][0]) {
						editObj[fieldMap.contactID] =
							editObj[fieldMap.contactID][0];
					} else {
						editObj[fieldMap.contactID] = '';
					}
				}

				if (editObj[fieldMap.projectID]) {
					if (editObj[fieldMap.projectID][0]) {
						editObj[fieldMap.projectID] =
							editObj[fieldMap.projectID][0];
					} else {
						editObj[fieldMap.projectID] = '';
					}
				}

				if (
					editObj[fieldMap.resourceID] &&
					relatedValueMap &&
					relatedValueMap.resourceID
				) {
					if (editObj[fieldMap.resourceID][0]) {
						if (Array.isArray(editObj[fieldMap.resourceID])) {
							editObj[fieldMap.resourceID] =
								editObj[fieldMap.resourceID][0];
						}
					} else {
						editObj[fieldMap.resourceID] = '';
					}
				}

				//we'll see what status maps to from fieldMap =)

				//arrays to string
				if (
					editObj[fieldMap.status] &&
					editObj[fieldMap.status].length
				) {
					editObj[fieldMap.status] = editObj[fieldMap.status][0];
				} else if (editObj[fieldMap.status]) {
					editObj[fieldMap.status] = '';
				}

				//Mutate resource if none is selected
				if (
					editObj[fieldMap.resource] &&
					editObj[fieldMap.resource].length === 1 &&
					editObj[fieldMap.resource][0] === ''
				) {
					editObj[fieldMap.resource] = [];
				}

				if (fieldMap.resource !== 'Owner.Name') {
					if (
						editObj[fieldMap.resource] &&
						editObj[fieldMap.resource].length
					) {
						//if resource is mapped as a custom field, it may have already been joined.
						if (typeof editObj[fieldMap.resource] === 'object') {
							editObj[fieldMap.resource] =
								editObj[fieldMap.resource].join(';');
						} else {
							editObj[fieldMap.resource] =
								editObj[fieldMap.resource];
						}
					} else if (editObj[fieldMap.resource]) {
						editObj[fieldMap.resource] = '';
					}
				}

				//multi day date objects like Campaigns need to shift end date only
				if (
					allDay === true &&
					event.start !== event.end &&
					editObj[fieldMap.end]
				) {
					editObj[fieldMap.end] = moment(editObj[fieldMap.end])
						.subtract(1, 'days')
						.format(allDayFormat);
				}

				//if allDay is unmapped we cannot be dragged to times or unclick allDay
				//allDay should be hidden as an option from the pop-over in this case
				if (!schedule.allowAllDay && !allDay && revertFunc) {
					result = [
						{
							errorCode:
								event.schedule.name +
								' are configured to not allow times in Source Settings.',
							message:
								event.schedule.name +
								' are configured to not allow times in Source Settings.',
						},
					];
					resultCallback(result);
					return;
				}

				if (editObj.RecurrenceStartDateOnly && editObj.ActivityDate) {
					delete editObj.ActivityDate;
				}

				//for single day events, duration should be one day (the way sf does things)
				var duration =
					event.start && event.end
						? event.end.days() - event.start.days()
						: 0;
				if ((!fieldMap.end || unused) && duration > 1) {
					result = [
						{
							errorCode:
								'The source ' +
								event.schedule.name +
								' does not have end mapped, so you cannot change the duration of this item. You can change this by mapping "end" to a field in the source settings for ' +
								event.schedule.name +
								'.',
							message:
								'The source ' +
								event.schedule.name +
								' does not have end mapped, so you cannot change the duration of this item. You can change this by mapping "end" to a field in the source settings for ' +
								event.schedule.name +
								'.',
						},
					];
					resultCallback(result);
					return;
				}

				//JY I think this block is dead 2023-01-11
				// var resourceChanged = false;
				// var originalResource;
				// var newResource = event.resource;
				// var c = 0;
				// if (event.beforeDrop) {
				// 	originalResource = event.beforeDrop.resource;
				// } else if (revertObject) {
				// 	originalResource = revertObject.resource;
				// }
				// // If resource isn't mapped it wouldn't be in the before drop or revert objects
				// if (!originalResource) {
				// 	originalResource = newResource;
				// }

				// if (originalResource.length === newResource.length) {
				// 	for (var ii in newResource) {
				// 		if (newResource[ii] === originalResource[ii]) {
				// 			c++;
				// 		}
				// 	}
				// 	resourceChanged = c === newResource.length ? false : true;
				// }

				var taskNotification;
				var eventNotification;
				var activityTime;
				var activityReminderTime;

				//begin checking if we need to make async queries before performing our edit (owner id and notification preferences).
				handleResource();

				function handleResource() {
					var objects = [];
					var nameFields = [];
					var resource = editObj[fieldMap.resource];
					//if resource is the owner, event is new, resoure is not specified, and standard activitied, then set the
					//resource to the current user so the reminder process is triggered and reminder values are set.
					if (
						!resource &&
						!event.eventID &&
						fieldMap.resource === 'Owner.Name' &&
						(schedule.objectName === 'Task' ||
							schedule.objectName === 'Event')
					) {
						resource = api.getUserInfo('accountFullName');
						editObj[fieldMap.resource] = resource;
					}
					if (resource) {
						if (
							fieldMap.resource === 'Owner.Name' &&
							(!relatedValueMap || !relatedValueMap.resourceID)
						) {
							//resource is mapped to Owner.Name, but not using the newer related methodology
							objects.push('User');
							nameFields.push('Name');
						} else if (
							fieldMap.resourceID &&
							relatedValueMap &&
							relatedValueMap.resourceID
						) {
							var resourceObjects = schedule.resourceObjects;
							for (var resourceObject in resourceObjects) {
								objects.push(
									resourceObjects[resourceObject].object
								);
								nameFields.push(
									resourceObjects[resourceObject].searchField
								);
							}
						}
						if (objects.length > 0) {
							resourceLookUp(objects, nameFields, resource);
						} else {
							//resource is not owner and not related, so proceed with PATCH process
							updateSalesforce();
						}
					} else {
						//resource is not being edited, proceed with PATCH process
						updateSalesforce();
					}
				}

				function resourceLookUp(objects, nameFields, resource) {
					var resourceResult;
					var queriesToRun = objects.length;
					var queriesCompleted = 0;
					if (fieldMap.resource === 'Owner.Name') {
						//Owner.Name is a special case. If we're clearing the value or the resource
						//is the logged in user then we know the id and can just proceed without looking it up
						if (
							resource.length === 0 ||
							resource[0] === api.getUserInfo('accountFullName')
						) {
							var result = {
								object: 'User',
								id: api.getUserInfo('accountId'),
							};
							processResource(result);
							return;
						}
					}
					if (resource.length === 0) {
						//resource is being cleared, so we don't need to look anything up, just set to null
						//and proceed with the PATCH process
						if (
							fieldMap.resourceID &&
							relatedValueMap &&
							relatedValueMap.resourceID
						) {
							editObj[fieldMap.resourceID] = null;
							delete editObj[fieldMap.resource];
						} else {
							editObj[fieldMap.resource] = null;
						}
						updateSalesforce();
					} else {
						//need to look up the resourceID based on the resourcevalue
						for (var i = 0; i < objects.length; i++) {
							var thisResource =
								typeof resource === 'Object'
									? resource[0]
									: resource;
							api.getIdByName(
								thisResource,
								objects[i],
								nameFields[i],
								callback
							);
						}
					}
					function callback(data) {
						if (data) {
							resourceResult = data;
						}
						queriesCompleted++;
						if (queriesCompleted === queriesToRun) {
							if (resourceResult) {
								processResource(resourceResult);
							} else {
								//no id found for this resource value
								var errorResult = [
									{
										errorCode: 'INVALID RESOURCE VALUE',
										message:
											'An id for ' +
											editObj[fieldMap.resource] +
											' could not be found to update this relationship.',
									},
								];
								resultCallback(errorResult);
							}
						}
					}
				}

				function processResource(data) {
					delete editObj[fieldMap.resource];
					if (
						fieldMap.resourceID &&
						relatedValueMap &&
						relatedValueMap.resourceID
					) {
						editObj[fieldMap.resourceID] = data.id;
					} else {
						//has to be OwnerId if resourceID isn't mapped
						editObj.OwnerId = data.id;
					}
					if (
						data.object === 'User' &&
						(schedule.objectName === 'Event' ||
							schedule.objectName === 'Task')
					) {
						//do this check even for the current user as no longer load these for the current user on start-up.
						//results are stored from call, so will only be done once per user per session
						if (editObj.OwnerId) {
							//assigning Activity to a different user so we need to look-up their prefs
							api.getUserPreferences(
								editObj.OwnerId,
								updateReminder
							);
						}
					} else {
						updateSalesforce();
					}
				}

				//update our edit object with our results for this user
				function updateReminder(data) {
					if (data.errorCode) {
						resultCallback([data]);
						return;
					}
					if (
						schedule.objectName === 'Event' &&
						!editObj.IsAllDayEvent &&
						data.eventRemindersOn
					) {
						activityTime = data.eventLeadTime;
						activityReminderTime = moment(editObj.StartDateTime)
							.subtract(activityTime, 'minutes')
							.format();
						editObj.IsReminderSet = data.eventRemindersOn;
						editObj.ReminderDateTime = activityReminderTime;
					} else if (
						(schedule.objectName === 'Task' &&
							data.taskRemindersOn) ||
						(schedule.objectName === 'Event' &&
							editObj.IsAllDayEvent &&
							data.eventRemindersOn)
					) {
						activityTime = data.taskLeadTime / 60;
						activityReminderTime = moment(event.start)
							.hour(activityTime)
							.minute(0)
							.second(0)
							.format();
						editObj.IsReminderSet = data.taskRemindersOn;
						editObj.ReminderDateTime = activityReminderTime;
					}
					updateSalesforce();
				}

				function updateSalesforce() {
					var view = seedcodeCalendar.get('view');
					//if this is a repeating event, note our view end for getting the repetitons we need to display
					var until = editObj.IsRecurrence
						? moment(view.end.format())
						: false;
					//remove any .Name properties and any fields with commas (calcs)
					var props = Object.getOwnPropertyNames(editObj);
					for (i in props) {
						if (
							props[i].indexOf('.') !== -1 ||
							props[i].indexOf(',') !== -1
						) {
							delete editObj[props[i]];
						} else if (editObj[props[i]] === '') {
							//blank strings aren't supported for all data types, but null is
							editObj[props[i]] = null;
						}
					}
					if (schedule.useAssignedResources) {
						editObj = createServiceRequest(editObj);
					}

					//!!CALLS TO api
					//if we have an id, we're doing an edit, otherwise a create.
					if (event.eventID) {
						var id = editObj.Id;
						delete editObj.Id;
						if (schedule.useAssignedResources) {
							api.updateServiceAppointmentRecord(
								connection.objectName,
								id,
								resultCallback,
								editObj,
								schedule.id
							);
						} else {
							api.updateRecord(
								connection.objectName,
								id,
								resultCallback,
								editObj,
								schedule.id
							);
						}
					} else {
						//create new record
						//if we have a recordtype associated with this source, then assign it here.
						if (schedule.recordType) {
							editObj.RecordTypeId = schedule.recordType;
						}
						//if no resource id and resource mapped to owner, then no resource specified, remove Id property to prevent error.
						if (
							!editObj.OwnerId &&
							fieldMap.resource === 'Owner.Name'
						) {
							delete editObj.OwnerId;
						}
						//now create
						if (editObj.IsRecurrence) {
						}
						newItem = true;
						if (schedule.useAssignedResources) {
							api.createServiceAppointmentRecord(
								connection.objectName,
								resultCallback,
								editObj,
								schedule.id
							);
						} else {
							api.createRecord(
								connection.objectName,
								resultCallback,
								editObj,
								schedule.id,
								until
							);
						}
					}
				}

				function resultCallback(result) {
					var message;
					var domEvents;
					var compareObjectStart = changes.hasOwnProperty('start')
						? changes.start
						: event.start;

					//If we are dragging this event currently we need to wait and see if the event has changed or not
					if (eventEditQueue.dragging) {
						window.setTimeout(function () {
							resultCallback(result);
						}, 250);
						return;
					}

					//We are editing the same event before the last save so exit the save
					if (editID && eventEditQueue[event.eventID] > editID) {
						return;
					} else {
						delete eventEditQueue[event.eventID];
					}

					//Check for errors and if we detect one revert the record
					if (result[0] && result[0].message) {
						if (config.passthroughEditErrors) {
							result[0].error = {
								message: result[0].message,
								code: result[0].errorCode,
							};
							if (callback) {
								callback(result[0]);
							}
						} else if (config.suppressEditEventMessages) {
							dragError();
						} else if (revertFunc) {
							if (
								result[0].errorCode.indexOf(
									'INVALID_SESSION_ID'
								) !== -1
							) {
								message =
									'There was an error saving the event and your changes will be reverted: ' +
									cleanError(result[0].message);
								utilities.showModal(
									'Operation Failed',
									message,
									'Revert Changes',
									dragError,
									'Re-Authorize',
									sessionError
								);
							} else {
								message =
									'There was an error saving the event and your changes will be reverted: ' +
									cleanError(result[0].message);
								utilities.showModal(
									'Operation Failed',
									message,
									null,
									null,
									'Revert Changes',
									dragError
								);
							}
						} else if (revertObject) {
							if (
								result[0].errorCode.indexOf(
									'INVALID_SESSION_ID'
								) !== -1
							) {
								message =
									'There was an error saving the event and your changes will be reverted: ' +
									cleanError(result[0].message);
								utilities.showModal(
									'Operation Failed',
									message,
									'Return To Event',
									cancelError,
									'Re-Authorize',
									sessionError
								);
							} else {
								message =
									'There was an error and your event could not be saved: ' +
									cleanError(result[0].message);
								utilities.showModal(
									'Operation Failed',
									message,
									'Return To Event',
									cancelError,
									'Revert Changes',
									confirmError
								);
							}
						}
						return;
					}

					result = mutateSalesforceEvents(result, schedule);

					// Add errror for dragging list item to "none"
					// if (result[0] && checkFieldForNoFilter && customFields) {
					// 	if (result[0][checkFieldForNoFilter] && result[0][checkFieldForNoFilter].indexOf(config.noFilterLabel) === -1) {
					// 		message = 'The previous selection for "' + customFields[checkFieldForNoFilter].name + '" has been removed and the new selection of "' + config.noFilterLabel + '" could not be retained due to validation settings of the field.';
					// 		utilities.showModal('The selection "' + config.noFilterLabel + '" could not be retained', message, null, null, 'OK', null);
					// 	}
					// }

					var eventResult;
					var modified;

					//if this is a repeating event, then remove as we'll re-render as its instance.
					if (newItem && result[0].recurringEventID) {
						element = seedcodeCalendar.get('element');
						domEvents = element.fullCalendar('clientEvents');
						for (var ii = 0; ii < domEvents.length; ii++) {
							if (event.eventID === domEvents[ii].eventID) {
								//case 49365. There's a difference when fullCalendarBridge.update(element, domEvents[ii]);
								//is used that causes a change false positive on "end" when opened and closed in the popover
								//working around this by removing and re-rendering - small performance difference
								element.fullCalendar(
									'removeEvents',
									domEvents[ii]
								);
							}
						}
					}

					if (
						result.length === 1 &&
						(!newItem || (newItem && !result[0].recurringEventID))
					) {
						//editing a single event, existing code
						eventResult = result[0];
						eventResult.allDay = event.allDay;

						eventResult._allDay = eventResult.allDay;

						// Normalize dates
						seedcodeCalendar
							.get('element')
							.fullCalendar(
								'buildEventDates',
								eventResult,
								eventResult
							);

						if (!config.suppressEditEventMessages) {
							if (
								eventResult.start &&
								!eventResult.allDay &&
								eventResult.start._d.getTime() !==
									compareObjectStart._d.getTime()
							) {
								mes =
									'SalesForce does not allow ' +
									event.schedule.name +
									' to have times. You can prevent this error by configuring ' +
									event.schedule.name +
									' to not allow times in "Source Settings".';
								utilities.showMessage(mes, 0, 8000, 'error');
							} else if (
								eventResult.end &&
								!(
									(changes.hasOwnProperty('end') &&
										changes.end.isSame(eventResult.end)) ||
									event.end.isSame(eventResult.end)
								)
							) {
								mes =
									event.schedule.name +
									' can only be single day events, or End is not mapped correctly.';
								utilities.showMessage(mes, 0, 8000, 'error');
							}
						}

						modified = applyEventChanges(event, eventResult);

						if (modified) {
							fullCalendarBridge.update(element, event);
						}

						if (callback) {
							callback(event);
						}
					} else {
						for (var i = 0; i < result.length; i++) {
							eventResult = result[i];

							eventResult.allDay = event.allDay;

							eventResult._allDay = eventResult.allDay;

							seedcodeCalendar
								.get('element')
								.fullCalendar('renderEvent', eventResult);
						}
						if (callback) {
							callback(event);
						}
					}

					//modal dialog errors
					function dragError() {
						if (revertFunc) {
							revertFunc(null, event, result[0]);
						}
					}
					function confirmError() {
						if (event.eventID) {
							applyEventChanges(event, revertObject);
							calendarIO.assignEventColor(event);
							fullCalendarBridge.update(element, event);
							if (callback) {
								callback(false);
							}
						} else {
							//Remove event from view
							fullCalendarBridge.removeEvents(element, event);
						}
					}
					function cancelError() {
						if (!event.eventStatus) {
							event.eventStatus = {};
						}

						event.eventStatus.revertObject = revertObject;

						if (event.eventStatus.isCustomAction) {
							applyEventChanges(event, revertObject);
						} else {
							event.eventStatus.showPopover = true;
							event.eventStatus.unsavedChanges = true;
							fullCalendarBridge.update(element, event);
							//Reset event status for custom action state
							event.eventStatus.isCustomAction = false;
						}
					}
				} //end callback
			}

			function createServiceAppointmentResource(resource, isPrimary) {
				return {
					serviceResourceId: resource.id,
					isRequiredResource: isPrimary ? true : false,
					isPrimaryResource: isPrimary ? true : false, // Mark only first one as primary
				};
			}

			function getMatchingResources(resourceNames) {
				const resourceResult = [];
				const resources = seedcodeCalendar.get('resources');
				const matchingResources = [];
				let primaryTechnican;
				let isPrimary = undefined;

				for (const resource of resources) {
					if (resourceNames.includes(resource.name)) {
						// Check to see if there is a technician and use first one as primary
						if (
							!primaryTechnican &&
							resource.description === 'Technician'
						) {
							primaryTechnican = resource;
						} else {
							matchingResources.push(resource);
						}
					}
				}

				if (primaryTechnican) {
					matchingResources.unshift(primaryTechnican);
				}

				for (const matchingResource of matchingResources) {
					if (isPrimary === undefined) {
						isPrimary = true;
					}
					resourceResult.push(
						createServiceAppointmentResource(
							matchingResource,
							isPrimary
						)
					);
					isPrimary = false;
				}
				return {query: resourceResult, resource: matchingResources[0]};
			}

			function createServiceRequest(request) {
				let serviceRequest = {};

				// Assign id if one exists
				if (request.Id) {
					serviceRequest.serviceAppointmentId = request.Id;
				}

				for (const property in request) {
					const key = property[0].toLowerCase() + property.slice(1);
					const isServiceField = isServiceAppointmentField(key);
					let assignedResources;
					if (key === 'schedStartTime' || key === 'schedEndTime') {
						if (!serviceRequest.serviceAppointment) {
							serviceRequest.serviceAppointment = {};
						}
						serviceRequest.serviceAppointment[key] =
							request[property];
					} else if (key === 'resource') {
						if (!serviceRequest.serviceAppointment) {
							serviceRequest.serviceAppointment = {};
						}
						if (!serviceRequest.assignedResources) {
							serviceRequest.assignedResources = [];
						}
						const resources = request.resource
							? request.resource.split(';')
							: '';
						assignedResources = request.resource
							? getMatchingResources(resources)
							: null;
						serviceRequest.assignedResources =
							assignedResources?.query || null;

						serviceRequest.serviceAppointment.serviceTerritoryId =
							assignedResources?.resource
								? assignedResources.resource.serviceTerritory.Id
								: null;
					} else if (
						isServiceField &&
						request[property] !== undefined
					) {
						if (!serviceRequest.serviceAppointment) {
							serviceRequest.serviceAppointment = {};
						}
						serviceRequest.serviceAppointment[key] =
							request[property];
					} else if (
						!isServiceField &&
						request[property] !== undefined
					) {
						if (!serviceRequest.serviceAppointment) {
							serviceRequest.serviceAppointment = {};
						}
						if (!serviceRequest.serviceAppointment.extendedFields) {
							serviceRequest.serviceAppointment.extendedFields =
								[];
						}
						serviceRequest.serviceAppointment.extendedFields.push({
							name: property,
							value: request[property],
						});
					} else if (request[property] !== undefined) {
						serviceRequest[key] = request[property];
					}
				}
				return serviceRequest;
			}

			function isServiceAppointmentField(field) {
				const serviceAppointmentFields = [
					'parentRecordId',
					'workTypeId',
					'serviceTerritoryId',
					'engagementChannelTypeId',
					'schedStartTime',
					'schedEndTime',
					'street',
					'city',
					'state',
					'postalCode',
					'country',
					'appointmentType',
					'appointmentMode',
					'attendeeLimit',
				];
				return serviceAppointmentFields.includes(field);
			}

			//Delete Event

			function deleteEvent(event, callback, schedule) {
				var connection = createConnection(schedule);
				var errorCode;

				api.deleteRecord(
					connection.objectName,
					event.eventID,
					processDelete
				);

				function processDelete(result) {
					var message;
					if (result[0] && result[0].errorCode) {
						if (
							result[0].errorCode.indexOf(
								'INVALID_SESSION_ID'
							) !== -1
						) {
							message =
								'There was an error saving the event and your changes will be reverted: ' +
								cleanError(result[0].message);
							utilities.showModal(
								'Operation Failed',
								message,
								'Re-Authorize',
								sessionError,
								'Return To Event',
								confirmError
							);
						} else {
							message =
								'There was an error deleting the item: ' +
								cleanError(result[0].message);
							utilities.showModal(
								'Operation Failed',
								message,
								null,
								null,
								'Return To Event',
								confirmError
							);
							return;
						}
					} else {
						errorCode = 0;
					}

					confirmDelete(event, errorCode, callback);
					function confirmError() {
						return;
					}
				}
			}

			function confirmDelete(event, error, callback) {
				var element = seedcodeCalendar.get('element');
				error = Number(error);
				if (error) {
					//Refetch events if we didn't cancel the request so we can update our events to reflect our events after error
					if (error > 1) {
						element.fullCalendar('refetchEvents');
					}
				} else {
					//Remove event from view
					fullCalendarBridge.removeEvents(element, event);
					event.removed = true;
					//editEvent.event = undefined; // Remove event data so we don't try and save the event when the popover closes
					if (callback) {
						callback();
					}
				}
			}

			//utility func for making errors titlecase

			function cleanError(str) {
				return str.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
					return (
						txt.charAt(0).toUpperCase() +
						txt.substr(1).toLowerCase()
					);
				});
			}

			// get contacts by object and criteria for contact selector

			function getContacts(
				callback,
				object,
				searchField,
				displayField,
				criteria,
				schedule
			) {
				if (
					apiType === 'fbk' &&
					searchField === 'Name' &&
					criteria.length === 1
				) {
					utilities.showMessage(
						'Your search term must have 2 or more characters.',
						0,
						5000
					);
					return;
				}
				function process(d) {
					//add a specific display property
					var dA = displayField.split(',');
					var i;
					var c;
					var thisA;
					for (i in d) {
						d[i].Id = d[i].Id.substring(0, 15);
						d[i].dbkDisplay = '';
						c = 0;
						for (c in dA) {
							thisA = dA[c].trim();
							if (c > 0) {
								d[i].dbkDisplayH +=
									'&nbsp;&nbsp;&nbsp;&nbsp;' +
									d[i][thisA].trim();
								d[i].dbkDisplay += ' ' + d[i][thisA].trim();
							} else {
								d[i].dbkDisplay = d[i][thisA];
								d[i].dbkDisplayH = d[i][thisA];
							}
							d[i].dbkDisplayH = d[i].dbkDisplayH.replace(
								/&nbsp;null/g,
								'&nbsp;'
							);
							d[i].dbkDisplayH = $sce.trustAsHtml(
								'<span>' + d[i].dbkDisplay + '</span>'
							);
						}
					}
					callback(d);
				}

				api.quickFind(
					object,
					searchField,
					displayField,
					criteria,
					process,
					schedule.relatedFirstWord
				);
			}

			function getProjects(
				callback,
				object,
				searchField,
				displayField,
				criteria,
				schedule
			) {
				if (
					apiType === 'fbk' &&
					searchField === 'Name' &&
					criteria.length === 1
				) {
					utilities.showMessage(
						'Your search term must have 2 or more characters.',
						0,
						5000
					);
					return;
				}
				function process(d) {
					//add a specific display property
					var dA = displayField.split(',');
					var i;
					var c;
					var thisA;
					var thisB;
					var val;
					var a;
					for (i in d) {
						d[i].dbkDisplay = '';
						c = 0;
						for (c in dA) {
							thisA = dA[c].trim();
							//if we're using a related field, then we need to manually drill down
							if (thisA.indexOf('.') !== -1) {
								a = thisA.split('.');
								thisA = a[0];
								thisB = a[1];
								val = d[i][thisA][thisB];
							} else {
								val = d[i][thisA];
							}
							if (c > 0) {
								d[i].dbkDisplayH +=
									'&nbsp;&nbsp;&nbsp;&nbsp;' + val;
								d[i].dbkDisplay += ' ' + val;
							} else {
								d[i].dbkDisplay = val;
								d[i].dbkDisplayH = val;
							}
							d[i].dbkDisplayH = d[i].dbkDisplayH.replace(
								/&nbsp;null/g,
								'&nbsp;'
							);
							d[i].dbkDisplayH = $sce.trustAsHtml(
								'<span>' + d[i].dbkDisplayH + '</span>'
							);
						}
					}
					callback(d);
				}
				api.quickFind(
					object,
					searchField,
					displayField,
					criteria,
					process,
					schedule.relatedFirstWord
				);
			}

			function getResources(
				callback,
				object,
				searchField,
				displayField,
				criteria,
				schedule
			) {
				if (
					apiType === 'fbk' &&
					searchField === 'Name' &&
					criteria.length === 1
				) {
					utilities.showMessage(
						'Your search term must have 2 or more characters.',
						0,
						5000
					);
					return;
				}
				function process(d) {
					//add a specific display property
					var dA = displayField.split(',');
					var i;
					var c;
					var thisA;
					var thisB;
					var val;
					var a;
					for (i in d) {
						d[i].dbkDisplay = '';
						c = 0;
						for (c in dA) {
							thisA = dA[c].trim();
							//if we're using a related field, then we need to manually drill down
							if (thisA.indexOf('.') !== -1) {
								a = thisA.split('.');
								thisA = a[0];
								thisB = a[1];
								val = d[i][thisA][thisB];
							} else {
								val = d[i][thisA];
							}
							if (c > 0) {
								d[i].dbkDisplayH +=
									'&nbsp;&nbsp;&nbsp;&nbsp;' + val;
								d[i].dbkDisplay += ' ' + val;
							} else {
								d[i].dbkDisplay = val;
								d[i].dbkDisplayH = val;
							}
							d[i].dbkDisplayH = d[i].dbkDisplayH.replace(
								/&nbsp;null/g,
								'&nbsp;'
							);
							d[i].dbkDisplayH = $sce.trustAsHtml(
								'<span>' + d[i].dbkDisplayH + '</span>'
							);
						}
					}
					callback(d);
				}
				api.quickFind(
					object,
					searchField,
					displayField,
					criteria,
					process,
					schedule.relatedFirstWord
				);
			}

			//Apply event changes returned from backend after an edit. Also returns wether the event was modified on the server
			function applyEventChanges(event, changes) {
				var modified;
				//Check if we need to apply a new schedule
				if (
					changes.schedule &&
					event.schedule.id !== changes.schedule.id
				) {
					modified = true;
					event.schedule = changes.schedule;
				}
				//Loop through all properties and set those
				for (var property in changes) {
					//Check string, numbers, and booleans
					if (
						(typeof changes[property] === 'undefined' ||
							typeof changes[property] === 'string' ||
							typeof changes[property] === 'number' ||
							typeof changes[property] === 'boolean' ||
							(typeof changes[property] === 'object' &&
								changes[property] === null)) &&
						property !== 'dateStart' &&
						property !== 'dateEnd' &&
						property !== 'timeStart' &&
						property !== 'timeEnd' &&
						property !== 'start' &&
						property !== 'end'
					) {
						if (
							(event[property] === undefined ||
								event[property] === null ||
								event[property] === '') &&
							(changes[property] === undefined ||
								changes[property] === null ||
								changes[property] === '')
						) {
							// we have a null value so let's just set back to event to normalize but don't mark anything as modified
							event[property] = changes[property];
						} else if (event[property] !== changes[property]) {
							if (property !== 'eventID') {
								modified = true;
							}
							event[property] = changes[property];
						}
					}

					//Check arrays
					else if (Array.isArray(changes[property])) {
						if (
							!utilities.arraysEqual(
								event[property],
								changes[property]
							)
						) {
							modified = true;
							event[property] = changes[property];
						}
					}
					//Check moment objects
					else if (
						moment.isMoment(changes[property]) ||
						property === 'start' ||
						property === 'end'
					) {
						if (
							!moment(changes[property]).isSame(
								moment(event[property])
							)
						) {
							modified = true;
							event[property] = changes[property];
						}
					}
				}
				return modified;
			}

			function sessionError(e) {
				var sandbox;
				var uri;
				if (!Sfdc.canvas.oauth.loggedin()) {
					sandbox = fbk.isSandbox();
					if (sandbox) {
						uri =
							'https://test.salesforce.com/services/oauth2/authorize';
					} else {
						uri =
							'https://login.salesforce.com/services/oauth2/authorize';
					}
					Sfdc.canvas.oauth.login({
						uri: uri,
						params: {
							response_type: 'token',
							client_id:
								'3MVG9sG9Z3Q1Rlbee3KicFdnnEKWHDa2Cg7S1VhpoITTE_GArMaRyoEGjPQ03jkAQBudjj7Easc6Ms3wjvXM4',
							redirect_uri: encodeURIComponent(
								'https://app.dayback.com/salesforce/callback.html'
							),
						},
					});
				}
				return false;
			}
		}
	}
})();
